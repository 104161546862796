@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.6rem;
  --typing-font:3rem;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
}
.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
nav
{
  padding:60px 30px 0px 30px ;
  flex:var(--sidebar-flex);
  background: rgb(238,103,143);
  background: linear-gradient(180deg, rgba(84,74,125,1) 50%, rgba(255,212,82,1) 100%);
}
.condiv
{
  padding: 100px ;
  background-color: #f4f5f9;
  flex:var(--condiv-flex);
}

.home
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}
.profilepic
{
  border-radius: 50%;
  width: 300px;
  margin: 30px;
}
.typingeffect
{
  font-size: var(--typing-font);
  margin-bottom: 30px;
}

nav ul{
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li
{
  margin:40px;
}

a
{
  text-decoration: none;
  color: white;
  cursor: pointer
}

.copy-link
{
  text-decoration: none;
  color: grey;
  cursor: pointer
}

.subtopic
{
  margin:10px 10px 30px 10px !important;
}
p{
  font-size: var(--fontsize-p);
}
h1,h2,h3,h4,p{
  margin: 10px;
}
.social
{
  /* position: absolute;
  margin-top: 20px;
  display: flex;
  bottom: 60px; */
  position: fixed;
  bottom: 40px;
  left: 20px;
  display: flex;
}
.social i
{
  padding-right: 30px;
  font-size: 25px;
  color: #fff;
}
.social i:hover
{
  color: #b185ff;
}
.SchoolCard
{
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
  background-color: #f7f9fc;
}
.SchoolCard:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.WorkCard
{
  border:0px solid #9a9b9c;
  display: flex;
  margin:30px 0px 30px 0px;
  background-color: #f7f9fc;
}
.WorkCard:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

/* .ProjectCard
{
  border:1px solid #e6e6e6;;
  display: flex;
  margin:30px 0px 30px 0px;
  background-color: #f7f9fc;
  width: 46%;
  height: 43%;
  padding-top: 12px;
  padding-left: 15px;
}

.ProjectCard:hover
{
  color: #1b1d20;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
} */

.active
{
  font-weight: bolder;
}
.skills ul li
{
  list-style-type:none;
  margin:30px;
}

@media all and (max-width: 450px) {
  :root {
    --fontsize-nav:1rem;
    --sidebar-flex:0;
    --typing-font:2rem;
  }
  .App
  {
    flex-direction: column;
  }
  .sidebar
  {
    padding: 30px;
    background: rgb(238,103,143);
    background: linear-gradient(90deg, rgba(238,103,143,1) 50%, rgba(132,124,252,1) 80%);
  }
  .condiv
  {
    padding: 50px ;
  }
  nav ul
  {
    display: flex;
  }
  nav ul li
  {
    margin: 10px;
    color: white;
  }
  .social
  {
    left:0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    bottom: 30px;
  }
  .social i
  {
    padding-right: 0px;
  }
}

.skillSet{
  border-right: thin solid black;
  padding: 5%;
  width: 25%;
}

.projectpic
{
  /* width: 17%; */
  height: 80px;
  /* margin: 30px; */
}

.projectLink{
  color: #013a7a;
}

.ProjectCard{
  padding-bottom: 4%;
}

.card {
  position: relative;
  width: 400px;
  max-width: 440px;
  height: 300;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  transition: box-shadow 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card:hover {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.card__image {
  position: relative;
  border-bottom: solid;
  border-width: thin;
}
.card__image:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  mix-blend-mode: screen;
  clip-path: circle(0% at var(--mouse-x, 0) var(--mouse-y, 0));
  opacity: 0;
  transition: all 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card:hover .card__image:after {
  opacity: 1;
  clip-path: circle(200% at var(--mouse-x, 0) var(--mouse-y, 0));
}
.card__image-decoration {
  position: absolute;
  bottom: 24px;
  left: 24px;
}
.card__content {
  padding: 24px;
}
.card__category {
  color: #808080;
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  text-decoration: none;
}
.card__category:hover,
.card__category:focus,
.card__category:active {
  color: #000;
  outline: 0;
}
.card__heading {
  color: #000;
  font-size: 32px;
  line-height: 40px;
}
.card__link {
  color: inherit;
  text-decoration: none;
  position: static;
  z-index: 1;
}
.card__link:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.card__category,
.card__action {
  position: relative;
  z-index: 2;
}
.card__description {
  margin-top: 16px;
}
.card__description > * + * {
  margin-top: 16px;
}
.card__actions {
  display: block;
  width: 100%;
  border-top: 1px solid #f2f2f2;
}
.card__actions {
  display: inline-block;
}
.button {
  display: inline-block;
  background-color: #fff;
  color: #808080;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}
.button:hover,
.button:focus,
.button:active {
  color: #000;
  outline: 0;
}
.label {
  background-color: #000;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.responsive-media {
  /* position: relative; */
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  text-align: center;
}
.responsive-media > img {
  width: 50%;
}
.responsive-media--widescreen {
  padding-bottom: 56.25%;
}

/* img {
  vertical-align: bottom;
  border: 0;
  max-width: 100%;
  width: auto;
  height: auto;

} */
